<script setup>
    import { computed } from "vue";

    const emit = defineEmits(["update:checked"]);

    const props = defineProps({
        checked: {
            type: [Array, Boolean],
            default: false,
        },
        value: {
            type: String,
            default: null,
        },
    });

    const proxyChecked = computed({
        get() {
            return props.checked;
        },

        set(val) {
            emit("update:checked", val);
        },
    });
</script>

<template>
    <input
        v-model="proxyChecked"
        type="checkbox"
        :value="value"
        class="rounded border-gray-300 text-indigo-600 shadow-xs focus:ring-indigo-500"
    />
</template>
